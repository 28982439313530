<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>Derechos del Estado de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="6" size-lg="4">
                        <ion-item>
                            <ion-label position="stacked">Seleccione Articulo</ion-label>
                            <ion-select value="0" v-model="article" placeholder="Seleccionar" interface="action-sheet">
                                <ion-select-option v-for="(article, i) in articles" :key="i" :value="article.article">
                                    {{article.text}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>                    
                        <h2 v-if="article !== '' && criterios.showCantidadBase">Cantidad base</h2>
                        <ion-item v-if="article !== '' && criterios.showCantidadBase">
                            <currency-input v-model="criteriosValue.CantidadBase"/>
                        </ion-item>

                        <ion-item v-if="criterios.extraUnidad">
                            <ion-label position="stacked">{{criterios.extraUnidad}}</ion-label>
                            <ion-input type="number" v-model="criteriosValue.extraUnidadValue"></ion-input>
                        </ion-item>

                        <ion-item v-if="criterios.extraSelectYesAndNot">
                            <ion-label>{{criterios.extraSelectYesAndNot}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelectYesAndNot_second">
                            <ion-label>{{criterios.extraSelectYesAndNot_second}}</ion-label>
                            <ion-select value="No" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue_second">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelect.length > 0">
                            <ion-label>Seleccionar opción</ion-label>
                            <ion-select interface="popover" v-model="criteriosValue.extraSelectValue">
                                <ion-select-option 
                                    v-for="opcion in criterios.extraSelect" 
                                    :key="opcion" 
                                    :value="opcion"
                                >
                                    {{opcion}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-button 
                            v-if="article !== '' && criterios.showCalcular" 
                            class="ion-margin-top"
                            @click="setButton()"
                        >
                            Cálcular
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center ion-margin-top">
                    <ion-col size="12" size-md="6" v-html="textResult" class="ion-text-center">
                        
                    </ion-col>
                </ion-row>
            </ion-grid>            
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'derechos-edomex-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonButton,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        CurrencyInput,
        IonInput,
    },
    data:()=>({
        article:'',
        issetCalcular: false,
        textResult:'',
        criterios: { //esto sirve para mostrar u ocultar elementos
            text:'',
            showCantidadBase: true,
            extraUnidad: '',
            extraSelectYesAndNot: '',
            extraSelectYesAndNot_second: '',
            extraSelect:[], showCalcular: false,
        },
        criteriosValue:{ //aqui se almacenan los datos
            CantidadBase: 0.00,
            extraUnidadValue: '',
            extraSelectYesAndNotValue:'No',
            extraSelectYesAndNotValue_second: '',
            extraSelectValue: ''   
        },
        articles: [ //Se introducen os articulos asi como sus criterios            	                
            {article:"A) Apeo y deslinde", text:"95 I. A) Apeo y deslinde",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"B) Capitulaciones matrimoniales sobre inmuebles", text:"95 I. B) Capitulaciones matrimoniales sobre inmuebles",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"C) - COMPRAVENTA - Transmisión de propiedad de inmuebles", text:"95 I. C) - COMPRAVENTA - Transmisión de propiedad de inmuebles",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"D) Compraventa a plazos, con reserva de dominio", text:"95 I. D) Compraventa a plazos, con reserva de dominio",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"E) Compraventa plazos, reserva de dominio viviendas interés social", text:"95 I. E) Compraventa plazos, reserva de dominio viviendas interés social",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"F) Inmatriculación judicial o administrativa de inmuebles", text:"95 I. F) Inmatriculación judicial o administrativa de inmuebles",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"G) Constitución de régimen de propiedad en condominio", text:"95 I. G) Constitución de régimen de propiedad en condominio",
                showCantidadBase: false,
                extraUnidad: 'N° de unidades privativas, fracción o lote',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"H) Otros actos inscribibles o anotables", text:"95 I. H) Otros actos inscribibles o anotables",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"H) Inscripciones régimen propiedad condominio viviendas interés social", text:"95 I. H) Inscripciones régimen propiedad condominio viviendas interés social",
                showCantidadBase: false,
                extraUnidad: 'N° de lotes o viviendas:',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"Sentencias que declaren la adquisición de la propiedad por usucapión", text:"95 I. Sentencias que declaren la adquisición de la propiedad por usucapión",
                showCantidadBase: false,
                extraUnidad: 'N° de sentencias',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"Cancelación por revocación, rescisión de las inscripciones de esta fracción", text:"95 I. Cancelación por revocación, rescisión de las inscripciones de esta fracción",
                showCantidadBase: false,
                extraUnidad: 'N° de inscripciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 II Inscripción de modificaciones relativas a inscripciones principales", text:"95 I. Inscripción de modificaciones relativas a inscripciones principales",
                showCantidadBase: false,
                extraUnidad: 'N° de inscripciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 II Inscripciones de actos que limiten derecho de propiedad", text:"95 II Inscripciones de actos que limiten derecho de propiedad",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 II Tratándose de copias de actas de embargo para su anotación preventiva", text:"95 II Tratándose de copias de actas de embargo para su anotación preventiva",
                showCantidadBase: false,
                extraUnidad: 'Monto de anotaciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 II Inscripción de la división de hipoteca, de acuerdo al art. 7.1118 CC", text:"95 II Inscripción de la división de hipoteca, de acuerdo al art. 7.1118 CC",
                showCantidadBase: false,
                extraUnidad: 'N° de inmuebles',
                extraSelectYesAndNot: '¿Vivienda de interés social, social progresiva o popular?',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 II Inscripción de hipotecas en las que intervengan los FIRA o FND", text:"95 II Inscripción de hipotecas en las que intervengan los FIRA o FND",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"95 II Inscripción de hipotecas para garantizar crédito viviendas interés social", text:"95 II Inscripción de hipotecas para garantizar crédito viviendas interés social",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"95 II Inscripción de la sustitución de fiduciario", text:"95 II Inscripción de la sustitución de fiduciario",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"95 II Cancelación por Extinción Fideicomiso Administración o Garantía", text:"95 II Cancelación por Extinción Fideicomiso Administración o Garantía",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Vivienda de interés social, social progresiva o popular?',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 III. Inscripción de actos cuyo registro sea necesario como acto previo", text:"95 III. Inscripción de actos cuyo registro sea necesario como acto previo",
                showCantidadBase: false,
                extraUnidad: 'N° de actos o documentos',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"95 III Cancelación de la inscripción a que alude esta fracción", text:"95 III Cancelación de la inscripción a que alude esta fracción",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"95 III Inscripción de la autorización para enajenar o gravar lotes o viviendas", text:"95 III Inscripción de la autorización para enajenar o gravar lotes o viviendas",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"95 III Inscripción de poderes generales especiales", text:"95 III Inscripción de poderes generales especiales",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 I. Escrituras constitutivas de personas jurídico colectivas", text:"97 I. Escrituras constitutivas de personas jurídico colectivas",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 II. Actas de asambleas de socios o de juntas de administradores", text:"97 II. Actas de asambleas de socios o de juntas de administradores",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 III. Otorgamiento o sustitución de poderes generales", text:"97 III. Otorgamiento o sustitución de poderes generales",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 IV. Emisiones de acciones, cédulas, certificados de participación", text:"97 IV. Emisiones de acciones, cédulas, certificados de participación",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 V. Fianzas de corredores", text:"97 V. Fianzas de corredores",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 VI. Depósito de la firma en facsímil de los administradores", text:"97 VI. Depósito de la firma en facsímil de los administradores",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 VII. Depósito de copia autorizada por balance", text:"97 VII. Depósito de copia autorizada por balance",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 IX Otros actos inscribibles o anotables", text:"97 IX Otros actos inscribibles o anotables",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"97 Cancelación de cualquiera de los actos contenidos en este artículo", text:"97 Cancelación de cualquiera de los actos contenidos en este artículo",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },            
            {article:"100 I A) De no inscripción", text:"100 I A) De no inscripción",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 I B) De no propiedad por cada nombre, denominación o razón social", text:"100 I B) De no propiedad por cada nombre, denominación o razón social",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 I C) De inscripción", text:"100 I C) De inscripción",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '¿Vivienda de interés social, social progresiva o popular?',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 I D) De libertad o de existencia de gravámenes", text:"100 I D) De libertad o de existencia de gravámenes",
                showCantidadBase: false,
                extraUnidad: 'N° de certificado',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 I Relacionados con adquisición viviendas interés social (FIRA o FND)", text:"100 I Relacionados con adquisición viviendas interés social (FIRA o FND)",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 I Tratándose de terrenos donde se construyan viviendas interés social", text:"100 I Tratándose de terrenos donde se construyan viviendas interés social",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 II. Informes sobre la existencia o inexistencia de testamentos", text:"100 II. Informes sobre la existencia o inexistencia de testamentos",
                showCantidadBase: false,
                extraUnidad: 'N° de informes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 III. Por compulsa de documentos", text:"100 III. Por compulsa de documentos",
                showCantidadBase: false,
                extraUnidad: 'N° de documentos',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 IV. Expedición de copias literales de asientos registrales", text:"100 IV. Expedición de copias literales de asientos registrales",
                showCantidadBase: false,
                extraUnidad: 'N° de predios',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 V. Certificado secuencia registral de inmueble o asiento registral", text:"100 V. Certificado secuencia registral de inmueble o asiento registral",
                showCantidadBase: false,
                extraUnidad: 'N° de asientos a certficar',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 VI. Búsqueda/expedición sin certificación informes contenidos en libros", text:"100 VI. Búsqueda/expedición sin certificación informes contenidos en libros",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"100 VII. Certificado de inscripción de dominio o consumación de usucapión", text:"100 VII. Certificado de inscripción de dominio o consumación de usucapión",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"100 VIII. Consulta de folio electrónico, sin certificación", text:"100 VIII. Consulta de folio electrónico, sin certificación",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"102 I. Expedición de testimonios, incluida la autorización", text:"102 I. Expedición de testimonios, incluida la autorización",
                showCantidadBase: false,
                extraUnidad: 'N° de hojas',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 III. Expedición de testimonio de escrituras mecanografiadas", text:"102 III. Expedición de testimonio de escrituras mecanografiadas",
                showCantidadBase: false,
                extraUnidad: 'N° de hojas',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 IV. Informe sobre existencia o inexistencia de testamento", text:"102 IV. Informe sobre existencia o inexistencia de testamento",
                showCantidadBase: false,
                extraUnidad: 'N° de informes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 V. Búsqueda de antecedentes de escrituras notariales", text:"102 V. Búsqueda de antecedentes de escrituras notariales",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: false,
            },
            {article:"102 VI. Autorización definitiva de escrituras de valor determinado", text:"102 VI. Autorización definitiva de escrituras de valor determinado",
                showCantidadBase: false,
                extraUnidad: 'N° de autorizaciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 VII. Autorización definitiva escrituras de valor indeterminado", text:"102 VII. Autorización definitiva escrituras de valor indeterminado",
                showCantidadBase: false,
                extraUnidad: 'N° de autorización',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 VIII. Cancelación de la disposición del patrimonio familiar", text:"102 VIII. Cancelación de la disposición del patrimonio familiar",
                showCantidadBase: false,
                extraUnidad: 'N° de cancelaciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 IX. Revocación, extinción y sustitución de poderes", text:"102 IX. Revocación, extinción y sustitución de poderes",
                showCantidadBase: false,
                extraUnidad: 'N° de revocaciones, extinciones o sustituciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 X. Depósito de aviso de testamento, corrección o revocación", text:"102 X. Depósito de aviso de testamento, corrección o revocación",
                showCantidadBase: false,
                extraUnidad: 'N° de depósitos',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 XI. Informe sobre existencia o inexistencia de poderes notariales", text:"102 XI. Informe sobre existencia o inexistencia de poderes notariales",
                showCantidadBase: false,
                extraUnidad: 'N° de informes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
            {article:"102 XII. Informe sobre existencia o inexistencia de escrituras y actos notariales", text:"102 XII. Informe sobre existencia o inexistencia de escrituras y actos notariales",
                showCantidadBase: false,
                extraUnidad: 'N° de informes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[], showCalcular: true,
            },
        ],
        
        tabla:[
            {hasta: 215885,   valueText:"2,342"},
            {hasta: 269857,   valueText:"7,030"},
            {hasta: 323828,   valueText:"11,720"},
            {hasta: 377800,   valueText:"16,406"},
            {hasta: 431773,   valueText:"21,141"},
            {hasta: 2500000,  valueText:"23,484"},
            {hasta: 5000000,  valueText:"28,181"},
            {hasta: null, valueText:"35,227"},
        ]
    }),
    watch:{
        article(){
            this.criterios = this.articles.find(item => this.article === item.article);
            this.calcular();
        }
    },
    methods:{
        setButton(){            
            this.issetCalcular=true;
            this.calcular();
        },
        multUnidades(precio){
            if(this.issetCalcular){
                this.textResult = `Derechos de registro $ ${this.criteriosValue.extraUnidadValue * precio}`;
                this.issetCalcular=false;
            }
        },
        calcular(){
            this.criteriosValue.CantidadBase = this.criteriosValue.CantidadBase < 1 ? 0 : this.criteriosValue.CantidadBase;
            this.textResult="";
            switch(this.criterios.article){
                case "A) Apeo y deslinde": 
                case "B) Capitulaciones matrimoniales sobre inmuebles":{
                    this.textResult = "<h3>Derechos de Registro: $865.00</h3>";
                    break;
                }
                case "C) - COMPRAVENTA - Transmisión de propiedad de inmuebles": 
                case "D) Compraventa a plazos, con reserva de dominio":
                case "F) Inmatriculación judicial o administrativa de inmuebles":
                case "H) Otros actos inscribibles o anotables":
                case "95 II Inscripciones de actos que limiten derecho de propiedad":{
                    if(this.issetCalcular){
                        let tabla = this.tabla.find(item => (this.criteriosValue.CantidadBase <= item.hasta || item.hasta === null));
                        this.textResult = `<h3>Derechos de Registro ${tabla.valueText}</h3>`;
                        this.issetCalcular=false;
                    }
                    break;
                }
                case "E) Compraventa plazos, reserva de dominio viviendas interés social":{
                    this.textResult = "<h3>Derechos de Registro: $97.00</h3>";
                    break;
                }
                case "G) Constitución de régimen de propiedad en condominio":{
                    this.multUnidades(1299);
                    break;
                }
                case "H) Inscripciones régimen propiedad condominio viviendas interés social":{
                    this.multUnidades(203);
                    break;
                }
                case "Sentencias que declaren la adquisición de la propiedad por usucapión":{
                    this.multUnidades(865);
                    break;                    
                }
                case "Cancelación por revocación, rescisión de las inscripciones de esta fracción":{
                    this.multUnidades(925);
                    break;
                }
                case "95 II Inscripción de modificaciones relativas a inscripciones principales":{
                    this.multUnidades(586);
                    break;
                }
                case "95 II Tratándose de copias de actas de embargo para su anotación preventiva":{
                    this.multUnidades(965);
                    break;
                }
                case "95 II Inscripción de la división de hipoteca, de acuerdo al art. 7.1118 CC":{
                    if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                        this.multUnidades(2236);
                    }else{
                        this.multUnidades(202);
                    }
                    break;
                }
                case "95 II Inscripción de hipotecas en las que intervengan los FIRA o FND":{
                    this.textResult = "<h3>Derechos de Registro: $ 1278.00</h3>";
                    break;
                }
                case "95 II Inscripción de hipotecas para garantizar crédito viviendas interés social":{
                    this.textResult = "<h3>Derechos de Registro: $ 202.00</h3>";
                    break;
                }
                case "95 II Inscripción de la sustitución de fiduciario":{
                    this.textResult = "<h3>Derechos de Registro: $ 2342.00</h3>";
                    break;
                }
                case "95 II Cancelación por Extinción Fideicomiso Administración o Garantía":{
                    if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                        this.textResult = "<h3>Derechos de Registro: $ 1854.00</h3>";
                    }else{
                        this.textResult = "<h3>Derechos de Registro: $ 97.00</h3>";
                    }
                    break;
                }
                case "95 III. Inscripción de actos cuyo registro sea necesario como acto previo":{
                    this.multUnidades(865);
                    break;
                }
                case "95 III Cancelación de la inscripción a que alude esta fracción":{
                    this.textResult = "<h3>Derechos de Registro: $ 394.00</h3>";
                    break;
                }
                case "95 III Inscripción de la autorización para enajenar o gravar lotes o viviendas":
                case "95 III Inscripción de poderes generales especiales":{
                    this.textResult = "<h3>Derechos de Registro: $ 866.00</h3>";
                    break;
                }
                case "97 I. Escrituras constitutivas de personas jurídico colectivas":
                case "97 II. Actas de asambleas de socios o de juntas de administradores":
                case "97 IV. Emisiones de acciones, cédulas, certificados de participación":
                case "97 IX Otros actos inscribibles o anotables":{
                    this.textResult = "<h3>Derechos de Registro: $ 2320.00</h3>";
                    break;
                }
                case "97 III. Otorgamiento o sustitución de poderes generales":{
                    this.textResult = "<h3>Derechos de Registro: $ 954.00</h3>";
                    break;
                }
                case "97 V. Fianzas de corredores":{
                    this.textResult = "<h3>Derechos de Registro: $ 1299.00</h3>";
                    break;
                }
                case "97 VI. Depósito de la firma en facsímil de los administradores":
                case "97 VII. Depósito de copia autorizada por balance":{
                    this.textResult = "<h3>Derechos de Registro: $ 2342.00</h3>";
                    break;
                }
                case "97 Cancelación de cualquiera de los actos contenidos en este artículo":{
                    this.textResult = "<h3>Derechos de Registro: $ 1728.00</h3>";
                    break;
                }
                case "100 I A) De no inscripción":{
                    this.multUnidades(1104);
                    break;
                }
                case "100 I B) De no propiedad por cada nombre, denominación o razón social":{
                    this.multUnidades(89);
                    break;
                }
                case "100 I C) De inscripción":{
                    if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                        this.multUnidades(1093);
                    }else{
                        this.multUnidades(221);
                    }
                    break;
                }
                case "100 I D) De libertad o de existencia de gravámenes":{
                    this.multUnidades(1272);
                    break;
                }
                case "100 I Relacionados con adquisición viviendas interés social (FIRA o FND)":{
                    this.multUnidades(221);
                    break;
                }
                case "100 I Tratándose de terrenos donde se construyan viviendas interés social":{
                    this.multUnidades(552);
                    break;
                }
                case "100 II. Informes sobre la existencia o inexistencia de testamentos":{
                    this.multUnidades(925);
                    break;
                }
                case "100 III. Por compulsa de documentos":{
                    this.multUnidades(89);
                    break;
                }
                case "100 IV. Expedición de copias literales de asientos registrales":{
                    this.multUnidades(1104);
                    break;
                }
                case "100 V. Certificado secuencia registral de inmueble o asiento registral":{
                    this.multUnidades(933);
                    break;
                }
                case "100 VI. Búsqueda/expedición sin certificación informes contenidos en libros":{
                    this.textResult = "<h3>Derechos de Registro: $ 486.00</h3>";
                    break;
                }
                case "100 VII. Certificado de inscripción de dominio o consumación de usucapión":{
                    this.multUnidades(1093);
                    break;
                }
                case "100 VIII. Consulta de folio electrónico, sin certificación":{
                    this.textResult = "<h3>Derechos de Registro: $ 89.00</h3>";
                    break;
                }
                case "102 I. Expedición de testimonios, incluida la autorización":{
                    this.multUnidades(89);
                    break;
                }

                case "102 III. Expedición de testimonio de escrituras mecanografiadas":{
                    this.multUnidades(118);
                    break;
                }
                case "102 IV. Informe sobre existencia o inexistencia de testamento":{
                    this.multUnidades(894);
                    break;
                }
                case "102 V. Búsqueda de antecedentes de escrituras notariales":{
                    this.textResult = "<h3>Derechos de Registro: $ 89.00</h3>";                    
                    break;
                }
                case "102 VI. Autorización definitiva de escrituras de valor determinado":{
                    this.multUnidades(1719);
                    break;
                }
                case "102 VII. Autorización definitiva escrituras de valor indeterminado":{
                    this.multUnidades(1835);
                    break;
                }
                case "102 VIII. Cancelación de la disposición del patrimonio familiar":{
                    this.multUnidades(865);
                    break;
                }
                case "102 IX. Revocación, extinción y sustitución de poderes":{
                    this.multUnidades(885);
                    break;
                }
                case "102 X. Depósito de aviso de testamento, corrección o revocación":{
                    this.multUnidades(89);
                    break;
                }
                case "102 XI. Informe sobre existencia o inexistencia de poderes notariales":{
                    this.multUnidades(88);
                    break;
                }
                case "102 XII. Informe sobre existencia o inexistencia de escrituras y actos notariales":{
                    this.multUnidades(89);
                    break;
                }
                default: {
                    console.log(":)");
                }
            }       
                //1719 NUMERO DE AUTORIZACIONES
                //1835 NUEMERO DE AUTORIZACION
                //865 NUMERO DE CONCELACION
                //885 NUMERO DE REVOCACIONES
                //89 NUMERO DE DEPOSITOS
                //88 NUMERO DE INFORMES
                //89 INFORME

            },
        toggleResult(){
            console.log("calcular2");
        }
    }
})
</script>
