<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>Derechos de la Ciudad de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="6" size-lg="4">
                        <ion-item>
                            <ion-label position="stacked">Seleccione Articulo</ion-label>
                            <ion-select value="0" v-model="article" placeholder="Seleccionar" interface="action-sheet">
                                <ion-select-option v-for="(article, i) in articles" :key="i" :value="article.article">
                                    {{article.text}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>                    
                        <h2 v-if="article !== '' && criterios.showCantidadBase">Cantidad base</h2>
                        <ion-item v-if="article !== '' && criterios.showCantidadBase">
                            <currency-input v-model="criteriosValue.CantidadBase"/>
                        </ion-item>

                        <ion-item v-if="criterios.extraUnidad">
                            <ion-label position="stacked">{{criterios.extraUnidad}}</ion-label>
                            <ion-input type="number" v-model="criteriosValue.ExtraUnidadValue"></ion-input>
                        </ion-item>

                        <ion-item v-if="criterios.extraSelectYesAndNot">
                            <ion-label>{{criterios.extraSelectYesAndNot}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelectYesAndNot_second">
                            <ion-label>{{criterios.extraSelectYesAndNot_second}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue_second">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.vivienda">
                            <ion-label>{{criterios.vivienda}}</ion-label>
                            <ion-select value="Otro" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.viviendaValue">
                                <ion-select-option value="Otro">Otro</ion-select-option>
                                <ion-select-option value="Social">Interés social</ion-select-option>
                                <ion-select-option value="Popular">Popular</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelect.length > 0">
                            <ion-label>Seleccionar opción</ion-label>
                            <ion-select interface="popover" v-model="criteriosValue.extraSelectValue">
                                <ion-select-option 
                                    v-for="opcion in criterios.extraSelect" 
                                    :key="opcion" 
                                    :value="opcion"
                                >
                                    {{opcion}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-button 
                            v-if="article !== '' && !criterios.showCalcular" 
                            class="ion-margin-top"
                            @click="setButton()"
                        >
                            Cálcular
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center ion-margin-top">
                    <ion-col size="12" size-md="6" v-html="textResult" class="ion-text-center">
                        
                    </ion-col>
                </ion-row>
            </ion-grid>            
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'derechos-cdmx-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonButton,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        CurrencyInput,
        IonInput,
    },
    data:()=>({
        article:'',
        textResult:'',
        issetCalcular: false,
        criterios: { //esto sirve para mostrar u ocultar elementos
            text:'',
            showCantidadBase: true,
            extraUnidad: '',
            extraSelectYesAndNot: '',
            extraSelectYesAndNot_second: '',
            extraSelect:[],
        },
        criteriosValue:{ //aqui se almacenan los datos
            CantidadBase: 0.00,
            extraUnidadValue: '',
            extraSelectYesAndNotValue:'',
            extraSelectYesAndNotValue_second: '',
            extraSelectValue: '',
            viviendaValue:'Otro',
        },
        articles: [ //Se introducen los articulos asi como sus criterios            
            {article:"196", text:"196. Cada inscripción, anotación o cancelación de asiento",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
                vivienda:"Tipo de vivienda"
            },
            /*{article:"197", text:"197. Devolución de documentos",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"198I", text:"198 I. Certificado de existencia o inexistencia de gravámenes",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Urgente?',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"198II", text:"198 II. Informe solicitado por Federación, Entidades, Mpios",
                showCantidadBase: false,
                extraUnidad: 'Número de inmuebles o personas físicas o morales',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"198III", text:"198 III. Certificados adquisición enajenación bienes inmuebles",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"198IV", text:"198 IV. Certificado de no inscripción de un bien inmueble",
                showCantidadBase: false,
                extraUnidad: 'N° de períodos de 5 años',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"198V", text:"198 V. Copia certificada de asientos registrales de un folio",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"198VI", text:"198 VI. Certificado de inscripción",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"199I", text:"199 I. Otorgamiento de poderes",
                showCantidadBase: false,
                extraUnidad: 'N° de poderes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"199II", text:"199 II. Revocación o renuncia de poderes",
                showCantidadBase: false,
                extraUnidad: 'N° de renuncias o revocaciones',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"200I", text:"200 I. Fianzas, contrafianzas u obligaciones solidarias",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"200II", text:"200 II. Sustitución de acreedor o deudor",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"200III", text:"200 III. División de crédito",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"200IV", text:"200 IV. Inscripción de individualización de gravámenes",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"200V", text:"200 V. Anotación de embargo de varios bienes",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"200VI", text:"200 VI. Asiento registral de la cancelación de hipoteca",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"201", text:"201. Ratificación de firmas ante el registrador",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"202", text:"202. Registro de cada acto al cumplimiento de la condición",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"203I", text:"203 I. Constitución del patrimonio familiar",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"203II", text:"203 II. Cancelación del patrimonio familiar",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"203III", text:"203 III. Anotación del régimen patrimonial del matrimonio",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"203V", text:"203 V. Aplicación de bienes por disolución de sociedad conyugal",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"204I", text:"204 I. Actos por los que se constituya fraccionamiento",
                showCantidadBase: false,
                extraUnidad: 'N° de lotes',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"204II", text:"204 II. Fusión, por cada lote",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"204III", text:"204 III. Constitución de régimen de propiedad en condominio",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"205I", text:"205 I. Matrícula de comerciante persona física",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"205II", text:"205 II. Constitución o aumento de capital",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"205III", text:"205 III. Corresponsalía mercantil, por su registro o cancelación",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"207I", text:"207 I. Depósito del Registro de Constitución",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"207II", text:"207 II. Informe de existencia Registro de Sociedades de Convivencia",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"208I", text:"208 I Búsqueda de antecedentes registrales de un inmueble",
                showCantidadBase: false,
                extraUnidad: 'N° de busquedas',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"208II", text:"208 II. Expedición de constancia de antecedentes registrales",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"208III", text:"208 III. Servicio de vinculación al Sistema Integral del RPP",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"208IV", text:"208 IV. Ejemplar del Boletín Registral en la fecha de su expedición",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"208V", text:"208 V. Búsqueda oficial de antecedentes registrales de un inmueble",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"210I", text:"210 I. Examen para aspirante de Notario",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"210II", text:"210 II. Examen de oposición para el ejercicio notarial",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"211I", text:"211 I. Búsqueda en los índices de protocolos Notariales, en el AGN",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"211II", text:"211 II. Revisión y la certificación de la razón de cierre",
                showCantidadBase: true,
                extraUnidad: 'N° de libros',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"211III", text:"211 III. Guarda definitiva en el AGN, de cada decena de protocolo",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"211IV", text:"211 IV. Guarda definitiva en el AGN, de cada libro registro cotejos",
                showCantidadBase: false,
                extraUnidad: 'N° de libros',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"212I", text:"212 I. Patente de Aspirante a Notario y Corredor Público",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"212II", text:"212 II. Patente de Notario y Corredor Público",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"212III", text:"212 III. Sello y/o firma",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"212IV", text:"212 IV. Convenios de Notarios y Corredores Públicos",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"212V", text:"212 V. Registro de la constancia de certificación",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"212VI", text:"212 VI. Convenios que inscriban los mediadores privados al CJAT",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"214I", text:"214 I. Expedición de testimonios o certificaciones de instrumentos",
                showCantidadBase: false,
                extraUnidad: 'N° de instrumentos',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214I a)", text:"214 I a). Certificación de instrumento sólo contenga testamento",
                showCantidadBase: false,
                extraUnidad: 'N° de testimonios',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214I b)", text:"214 I b). Certificación de instrumento sólo contenga mandato o poderes",
                showCantidadBase: false,
                extraUnidad: 'N° de testimonios',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214I c)", text:"214 I c). Certificación de instrumento sólo contenga fe de hechos",
                showCantidadBase: false,
                extraUnidad: 'N° de testimonios',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214I d)", text:"214 I d). Certificación de instrumento contenga tomos completos apéndice",
                showCantidadBase: false,
                extraUnidad: 'N° de testimonios',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214I e)", text:"214 I e). Certificación instrumento sólo contenga documento Voluntad Anticipada",
                showCantidadBase: false,
                extraUnidad: 'N° de certificados',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            /*{article:"214II", text:"214 II. Cualquier anotación marginal o complementaria en un protocolo",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214III", text:"214 III. Registro de avisos de testamentos",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },
            {article:"214III", text:"214 III. Informe respecto al registro o depósito de testamentos",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"214IV", text:"214 IV. Razón de haberse cumplido requisitos legales instrumento notarial",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
                showCalcular: true
            },
            /*{article:"214V", text:"214 V. Registro avisos dados por notarios en caso designaciones tutor cautelar",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
            },*/
            {article:"otros", text:"otros",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelectYesAndNot_second: '',
                extraSelect:[],
                showCalcular: true
            },
        ],
        tabla:[
            {
                limiteSuperio: 672150,
                resultado: 2073
            },
            {
                limiteSuperio: 806580,
                resultado: 6219
            },
            {
                limiteSuperio: 941010,
                resultado: 10365
            },
            {
                limiteSuperio: 1075440,
                resultado: 14511
            },
            {
                limiteSuperio: null,
                resultado: 20763
            },
            
        ]
    }),
    watch:{
        article(){
            this.criterios = this.articles.find(item => this.article === item.article);
            this.calcular();
        }
    },
    methods:{
        setButton(){            
            this.issetCalcular=true;
            this.calcular();
        },
        impresionDerechos(derechos){
            if(this.issetCalcular){                                            
                this.textResult = `<h3>Derechos de Registro $ ${derechos * this.criteriosValue.extraUnidadValue}</h3>`;
                this.issetCalcular=false;
            }
        },
        calcular(){
            this.criteriosValue.CantidadBase = this.criteriosValue.CantidadBase < 1 ? 0 : this.criteriosValue.CantidadBase;
            this.textResult="";
            switch(this.criterios.article){
                case "196":{                    
                    let tabla = this.tabla.find(item => (this.criteriosValue.CantidadBase <= item.limiteSuperio || item.limiteSuperio===null));                    
                    if(this.issetCalcular){
                        let derechos = tabla.resultado;
                        console.log(this.criteriosValue.viviendaValue);
                        if(this.criteriosValue.viviendaValue === "Social"){
                            derechos = derechos*.20;
                        }else if(this.criteriosValue.viviendaValue === "Popular"){
                            derechos = derechos*.40;
                        }
                        this.textResult = `<h3>Derechos de Registro $ ${derechos}</h3>`;
                        this.issetCalcular=false;
                    }
                    break;
                }
                case "198I":{
                    if(this.criteriosValue.extraSelectYesAndNot === "No"){
                        this.impresionDerechos(702);
                    }else{
                        this.impresionDerechos(1406);
                    }
                    break;
                }
                case "198II":{
                    this.impresionDerechos(1306);
                    break;
                }
                case "198III":{                    
                    this.impresionDerechos(631);                    
                    break;
                }
                case "198IV":{                    
                    this.impresionDerechos(447);
                    break;
                }
                case "199I":
                case "199II":{
                    this.impresionDerechos(952);
                    break;
                }
                case "204I":{
                    this.impresionDerechos(1306);
                    break;
                }
                case "208I":{
                    this.impresionDerechos(633);
                    break;
                }
                case "211II":{
                    this.impresionDerechos(1628);
                    break;
                }
                case "211III":{
                    console.log("DUDA")
                    break;
                }
                case "211IV":{
                    this.impresionDerechos(1628);
                    break;
                }
                case "2014I":{
                    this.impresionDerechos(3485);
                    break;
                }
                case "214I a)":
                case "214I b)":
                case "214I c)":
                    {
                    this.impresionDerechos(696);
                    break;
                }
                case "214I d)":
                    {
                    this.impresionDerechos(977);
                    break;
                }
                case "214IV":{
                    this.textResult = "Derechos de registro $3,485.00";
                    break;
                }
                case "otros":{
                    this.textResult = `
                        <p>Art. 214 II. Nota marginal o complementaria <br/>
                        Por nota marginal o complementaria.......................              92.00</p>

                        <p>Art. 214 III. Registro de aviso de testamentos<br/>
                        Por registro de aviso....................................              89.00</p>

                        <p>Art. 248 IV. Compulsa de documentos <br/>
                        Por hoja........................ 11.50</p>

                        <p>Art. 248 VI. Legalización de firma y sello <br/>
                        Por legalización (docto público).................................             105.00</p>

                        <p>Art. 248 VII. Apostilla en documento público<br/>
                        Por apostilla ...................................................             105.00</p>
                    `;
                    break;
                }
            }
        }
    }
})
</script>
