<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>Arancel del Estado de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="6" size-lg="4">
                        <ion-item>
                            <ion-label position="stacked">Seleccione Articulo</ion-label>
                            <ion-select value="0" v-model="article" placeholder="Seleccionar" interface="action-sheet">
                                <ion-select-option v-for="(article, i) in articles" :key="i" :value="article.article">
                                    {{article.text}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>                    
                        <h2 v-if="article !== '' && criterios.showCantidadBase">Cantidad base</h2>
                        <ion-item v-if="article !== '' && criterios.showCantidadBase">
                            <currency-input v-model="criteriosValue.CantidadBase"/>
                        </ion-item>

                        <ion-item v-if="criterios.extraUnidad !== ''">
                            <ion-label position="stacked">{{criterios.extraUnidad}}</ion-label>
                            <ion-input type="number" v-model="criteriosValue.extraUnidadValue"></ion-input>
                        </ion-item>

                        <ion-item v-if="criterios.extraSelectYesAndNot !== ''">
                            <ion-label>{{criterios.extraSelectYesAndNot}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelectYesAndNotSecond">
                            <ion-label>{{criterios.extraSelectYesAndNotSecond}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotSecondValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelect.length > 0">
                            <ion-label>Seleccionar opción</ion-label>
                            <ion-select interface="popover" v-model="criteriosValue.extraSelectValue">
                                <ion-select-option 
                                    v-for="opcion in criterios.extraSelect" 
                                    :key="opcion" 
                                    :value="opcion"
                                >
                                    {{opcion}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-button 
                            v-if="article !== '' && !criterios.hideCalcular" 
                            class="ion-margin-top"
                            @click="issetCalc()"
                        >
                            Cálcualr
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                    <ion-col size="12" size-md="2" v-html="impresionText" class="ion-text-center">

                    </ion-col>
                </ion-row>
            </ion-grid>            
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'isabi-edomex-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonButton,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        CurrencyInput,
        IonInput,
    },
    data:()=>({
        article:'',
        impresionText: '',
        issetCalculo: false,
        criterios: { //esto sirve para mostrar u ocultar elementos
            text:'',
            showCantidadBase: true,
            extraUnidad: '',
            extraSelectYesAndNot: '',
            extraSelect:[],
        },
        criteriosValue:{ //aqui se almacenan los datos
            CantidadBase: 0.00,
            extraUnidadValue: '',
            extraSelectYesAndNotValue:'No',
            extraSelectYesAndNotSecondValue:'No',
            extraSelectValue: ''   
        },
        articles: [ //Se introducen os articulos asi como sus criterios
            {
                article:"6",text:"6. Traslativo de dominio",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"7",text:"7. Traslativo vivienda social progresiva, interés social",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"8",text:"8. Transmisión propiedad cuyo dominio se hubiese reservado",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true,
            },
            {
                article:"10",text:"10. Cancelación de hipoteca o extinción de obligaciones",
                showCantidadBase: false,
                extraUnidad: 'N° de cancelaciones',
                extraSelectYesAndNot: '¿Vivienda social o popular?',
                extraSelect:[],
            },
            {
                article:"11",text:"11. Cancelación de garantías de créditos puente",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true
            },
            {
                article:"12",text:"12. Régimen de propiedad en condominio, fraccionamiento",
                showCantidadBase: false,
                extraUnidad: 'N° de unidades privadas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"14",text:"14. Prestaciones periódicas con monto determinado",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"15",text:"15. Testamentos",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Testamento público simplificado?',
                extraSelectYesAndNotSecond:'¿Fuera de la notaría?',
                extraSelect:[],
            },
            {
                article:"16",text:"16. Iniciación del trámite de la sucesión testamentaria",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true
            },
            /*{
                article:"17",text:"17. Inicio de la sucesión en la intestamentaria",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"18",text:"18. Declaración de herederos y aceptación del cargo albacea",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"19",text:"19. Formulación y protocolización del inventario y avalúo",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"20",text:"20. Escritura de adjudicación de los bienes de la herencia",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"21",text:"21. Convenio de divorcio asentado en escritura pública",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },*/
            {
                article:"22",text:"22. Constituciones de personas jurídico colectivas",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
/*            {
                article:"23",text:"23. Aumento/disminución aportaciones personas jurídico colectivas", 
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },*/
            {
                article:"24",text:"24. Protocolización de actas de asamblea",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true,
            },
            {
                article:"25",text:"25. Protocolización de documentos otorgados en el extranjero",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true,
            },
            /*{
                article:"26",text:"26. Poderes o mandatos, sustitución o protocolización de ellos",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"27",text:"27. Declaraciones o informaciones testimoniales",
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },*/ 
            {
                article:"28",text:"28. Escrituras o actas que no tengan valor",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
                hideCalcular: true
            },
            {
                article:"29",text:"29. Cotejo de documentos",
                showCantidadBase: false,
                extraUnidad: 'N° de páginas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"30",text:"30. Cotejo de acta parroquial",
                showCantidadBase: false,
                extraUnidad: 'N° de horas o fracción',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"31",text:"31. Ratificación de firmas personas jurídico colectivas",
                showCantidadBase: false,
                extraUnidad: 'N° de personas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"32",text:"32. Ratificación de firmas personas físicas",
                showCantidadBase: true,
                extraUnidad: 'N° de peronas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"33",text:"33. Notificaciones, interpretaciones, requerimientos, fe de hechos",
                showCantidadBase: false,
                extraUnidad: 'N° de horas o fracción:',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"34",text:"34. Expedición de segundo y ulterior testimonios",
                showCantidadBase: false,
                extraUnidad: 'N° de páginas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {
                article:"39",text:"39. Estudio/preparación operaciones no se lleguen asentar",
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
        ],
        uma:96.22,
        tabla:[
            {
                limInferior:1.00,
                limSuperior:130000,
                cuota:80,
                porc:0,
            },
            {
                limInferior:130001,
                limSuperior:260000,
                cuota:80,
                porc:1.620,
            },
            {
                limInferior:260001,
                limSuperior:520000,
                cuota:116,
                porc:1.600,
            },
            {
                limInferior:520001,
                limSuperior:1040000,
                cuota:186,
                porc:1.550,
            },
            {
                limInferior:1040001,
                limSuperior:2080000,
                cuota:320,
                porc:0.990,
            },
            {
                limInferior:2080001,
                limSuperior:4160000,
                cuota:501,
                porc:0.820,
            },
            {
                limInferior:4160001,
                limSuperior:8320000,
                cuota:790,
                porc:0.800,
            },
            {
                limInferior:8320001,
                limSuperior:16640000,
                cuota:1354,
                porc:0.750,
            },
            {
                limInferior:16640001,
                limSuperior:null,
                cuota:2244,
                porc:0.700,
            },
        ],
        tabla2:[
            {
                limiteSuperior: 20150,
                umas: 20
            },
            {
                limiteSuperior: 30225,
                umas: 21
            },
            {
                limiteSuperior: 40300,
                umas: 25
            },
            {
                limiteSuperior: 50375,
                umas: 27
            },
            {
                limiteSuperior: 60450,
                umas: 30
            },
            {
                limiteSuperior: 80600,
                umas: 33
            },
            {
                limiteSuperior: 100750,
                umas: 37
            },
            {
                limiteSuperior: null,
                umas: 39
            },
        ],
        tabla3:[//articulo N° 10
            {
                limiteSuperior: 500000,
                umas:84,
            },
            {
                limiteSuperior: 1000000,
                umas: 126,
            },
            {
                limiteSuperior: 2000000,
                umas: 168,
            },
            {
                limiteSuperior: 3000000,
                umas: 210,
            },
            {
                limiteSuperior: 4000000,
                umas: 252,
            },
            {
                limiteSuperior: 5000000,
                umas: 294,
            },
            {
                limiteSuperior: 6000000,
                umas: 336,
            },
            {
                limiteSuperior: 7000000,
                umas: 378,
            },
            {
                limiteSuperior: 8000000,
                umas: 420,
            },
            {
                limiteSuperior: 9000000,
                umas: 462,
            },
            {
                limiteSuperior: 10000000,
                umas: 504,
            },
            {
                limiteSuperior: null,
                umas: 546,
            },
        ]
    }),
    watch:{
        article(){
            this.criterios = this.articles.find(item => this.article === item.article);            
            this.calcular();
        }
    },
    methods:{
        impresionTraslado(cantidadBase, arancel){
            const iva = arancel * .16;
            const total = arancel + iva;
            return `
                <p>Subtotal $ ${cantidadBase.toFixed(2)}</p>
                <v-divider></v-divider>
                <p>Arancel ${arancel.toFixed(2)}</p>
                <v-divider></v-divider>
                <p>IVA: ${iva.toFixed(2)}</p>
                <p>Total: ${total.toFixed(2)}</p>
                `;
        },
        issetCalc(){
            this.issetCalculo = true;
            this.calcular();            
        },
        calcular(){
            this.criteriosValue.cantidadBase = this.criteriosValue.cantidadBase < 1 ? 0 : this.criteriosValue.cantidadBase;
            this.impresionText="";
            switch(this.article){
                case '6': 
                case '14': {                    
                    if(this.issetCalculo){
                        const rango = this.tabla.find(item => (this.criteriosValue.CantidadBase < item.limSuperior || item.limSuperior===null));
                        const calc_tabla = (this.criteriosValue.CantidadBase - rango.limInferior) * (rango.porc/100);
                        const arancel = (rango.cuota * this.uma) + calc_tabla;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '7':{
                    if(this.issetCalculo){
                        const rango = this.tabla2.find(item => (this.criteriosValue.CantidadBase < item.limiteSuperior || item.limiteSuperior===null));
                        const arancel = this.uma * rango.umas;
                        this.impresionText = this.impresionTraslado(this.criteriosValue.CantidadBase, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '8':{
                    this.impresionText = this.impresionTraslado(7697.60, 7697.60);
                    break;
                }
                case '10':{
                    if(this.issetCalculo){
                        let arancel = 0;                    
                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){                            
                            arancel = 6735.40 * this.criteriosValue.extraUnidadValue;
                            this.impresionText = this.impresionTraslado(arancel, arancel);
                        }else{
                            arancel = 3367.70 * this.criteriosValue.extraUnidadValue;
                            this.impresionText = this.impresionTraslado(arancel, arancel);
                        }
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '11':{
                    this.impresionText = this.impresionTraslado(1924.40, 1924.40);
                    break;
                }
                case '12':{
                    if(this.issetCalculo){
                        const arancel = 3367.70 * this.criteriosValue.extraUnidadValue;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '15':{
                    if(this.issetCalculo){
                        let arancel;
                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                            arancel = 11 * this.uma;
                        }else{
                            arancel = 34 * this.uma;
                        }
                        if(this.criteriosValue.extraSelectYesAndNotSecondValue === "Si"){
                            arancel = arancel * 2;
                        }
                        this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '16':{
                    this.impresionText = this.impresionTraslado(9814.44, 9814.44);
                    break;
                }
                case '22':{
                    if(this.issetCalculo){
                        const rango = this.tabla3.find(item => (this.criteriosValue.CantidadBase < item.limSuperior || item.limSuperior===null));
                        const arancel = this.uma * rango.umas;
                        this.impresionText = this.impresionTraslado(this.criteriosValue.CantidadBase, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '24':{
                    this.impresionText = this.impresionTraslado(7024.06, 7024.06);
                    break;
                }
                case '25':{
                    this.impresionText = this.impresionTraslado(4811, 4811);
                    break;
                }
                case '28':{
                    this.impresionText = this.impresionTraslado(1924.40, 1924.40);
                    break;
                }
                case '29':{
                    if(this.issetCalculo){
                        let arancel = this.criteriosValue.extraUnidadValue * 384.88;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '30':{
                    if(this.issetCalculo){
                        let arancel = this.criteriosValue.extraUnidadValue * 4811;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '31':{
                    if(this.issetCalculo){
                        let arancel = this.criteriosValue.extraUnidadValue * 2886.60;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '32':{
                    if(this.issetCalculo){
                        let arancel = this.criteriosValue.extraUnidadValue * 3367.70;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '33':{
                    if(this.issetCalculo){
                        let arancel = this.criteriosValue.extraUnidadValue * 4811.00;
                        this.impresionText = this.impresionTraslado(arancel, arancel);
                        this.issetCalculo = false;
                    }
                    break;
                }
                case '39':{
                    this.impresionText = this.impresionTraslado(1924.40, 1924.40);
                    break;
                }
            }
        },
        toggleResult(){
            console.log("calcular2");
        }
    }
})
</script>
