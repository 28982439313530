<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>Arancel de la ciudad de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="6" size-lg="4">
                        <ion-item>
                            <ion-label position="stacked">Seleccione Articulo</ion-label>
                            <ion-select value="0" v-model="article" placeholder="Seleccionar" interface="action-sheet">
                                <ion-select-option v-for="(article, i) in articles" :key="i" :value="article.article">
                                    {{article.text}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>                    
                        <h2 v-if="article !== '' && criterios.showCantidadBase">Cantidad base</h2>
                        <ion-item v-if="article !== '' && criterios.showCantidadBase">
                            <currency-input v-model="criteriosValue.cantidadBase"/>
                        </ion-item>

                        <ion-item v-if="criterios.extraUnidad !== ''">
                            <ion-label position="stacked">{{criterios.extraUnidad}}</ion-label>
                            <ion-input type="number" v-model="criteriosValue.extraUnidadValue"></ion-input>
                        </ion-item>

                        <ion-item v-if="criterios.extraSelectYesAndNot !== ''">
                            <ion-label>{{criterios.extraSelectYesAndNot}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelectYesAndNotSecond">
                            <ion-label>{{criterios.extraSelectYesAndNotSecond}}</ion-label>
                            <ion-select value="0" placeholder="Seleccionar" interface="popover" v-model="criteriosValue.extraSelectYesAndNotSecondValue">
                                <ion-select-option value="Si">Si</ion-select-option>
                                <ion-select-option value="No">No</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item v-if="criterios.extraSelect.length > 0">
                            <ion-label>Seleccionar opción</ion-label>
                            <ion-select interface="action-sheet" v-model="criteriosValue.extraSelectValue">
                                <ion-select-option 
                                    v-for="opcion in criterios.extraSelect" 
                                    :key="opcion" 
                                    :value="opcion"
                                >
                                    {{opcion}}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-button 
                            v-if="article !== '' && !criterios.showCalcular" 
                            class="ion-margin-top"
                             @click="issetCalc()"
                        >
                            Cálcular
                        </ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-center ion-margin-top">
                    <ion-col size="12" size-md="6" v-html="textResult" class="ion-text-center">
                        
                    </ion-col>
                </ion-row>
            </ion-grid>            
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'isabi-cdmx-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonButton,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        CurrencyInput,
        IonInput,
    },
    data:()=>({
        article:'',
        textResult:'',
        issetCalcular: false,
        criterios: { //esto sirve para mostrar u ocultar elementos
            text:'',
            showCantidadBase: true,
            extraUnidad: '',
            extraSelectYesAndNot: '',
            extraSelect:[],
        },
        criteriosValue:{ //aqui se almacenan los datos
            cantidadBase: 0.00,
            extraUnidadValue: '',
            extraSelectYesAndNotValue:'No',
            extraSelectYesAndNotSecondValue:'No',
            extraSelectValue: ''   
        },
        articles: [ //Se introducen os articulos asi como sus criterios
            {article:"15", text:'15° Operaciones traslativas de bienes o derechos',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"16", text:'16° Prestaciones periódicas con monto determinado',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"17", text:'17° Contratos de crédito, mutuo, reconocimiento adeudo',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"18", text:'18° Extinción o cancelación de obligaciones',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"19I", text:'19°. Contratos con reserva de dominio',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Contrato de promesa?',
                extraSelect:[],
            },
            {article:"19II", text:'19° Transmisión propiedad cuyo dominio hubiere reservado',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Contrato de promesa?',
                extraSelect:[],
            },
            {article:"20", text:'20° Constitución del régimen de propiedad en condominio',
                showCantidadBase: true,
                extraUnidad: 'Unidades privativas',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"21", text:'21° Modificaciones al régimen de propiedad en condominio',
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: 'Seleccionar opción',
                extraSelect:[],
            },
            {article:"22", text:'22° Instrumentos relativos a sociedades civiles',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            /*{article:"22II", text:'22° Aumentos o disminuciones de capital social',
                showCantidadBase: true,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },*/
            {article:"22III", text:'22° Protocolización documentos personas morales extranjeras',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas del documento',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"23", text:'23° Protocolización de actas, de asambleas o sesiones',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas del instrumento',
                extraSelectYesAndNot: '¿Aumento o disminución de capital?',
                extraSelect:[],
            },
            {article:"24I", text:'24° Poderes, sustitución, revocación personas físicas',
                showCantidadBase: false,
                extraUnidad: 'N° de mandantes o poderdantes',
                extraSelectYesAndNot: '¿Tiene el carácter de irrevocable?',
                extraSelect:[],
            },
            {article:"24II", text:'24° Poderes, sustitución, revocación personas morales',
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '¿Otorgado en la escritura constitutiva?',
                extraSelectYesAndNotSecond:'¿Tiene el carácter de irrevocable?',
                extraSelect:[],
            },
            {article:"24IV", text:'24° Poderes, sustitución, revocación Mipymes y Pymes',
                showCantidadBase: false,
                extraUnidad: 'N° de mandantes o poderdantes:',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"25", text:'25° Testamentos, documentos de voluntad anticipada',
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:["Testamento en la Notaría", "Testamento fuera de la notaría", "Documentos de voluntad anticipada", "Tutela cautelar"],
            },
            {article:"26I", text:'26° Trámites sucesorios',
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:["Testamentaría", "Intestamentaría"],
            },
            {article:"26II", text:'26° Protocolización del inventario y avalúos',
                showCantidadBase: false,
                extraUnidad: 'Valor del activo inventariado',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"26III", text:'26° Escritura de adjudicación de bienes',
                showCantidadBase: false,
                extraUnidad: 'Valor del activo adjudicado',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"27", text:'27° Declaraciones o informaciones testimoniales',
                showCantidadBase: false,
                extraUnidad: '',
                extraSelectYesAndNot: '',
                extraSelect:["Perosna fisica", "Persona moral"],
            },
            {article:"28I", text:'28° ¿Todos los interesados son personas físicas?',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas del documento',
                extraSelectYesAndNot: '¿Todos los interesados son personas físicas?',
                extraSelect:[],
            },
            {article:"28II", text:'28° Ratificación de firmas con valor determinado',
                showCantidadBase: true,
                extraUnidad: 'N° de páginas del documento',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"29", text:'29° Operaciones diversas sin valor',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas del instrumento',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"30", text:'30° Diligencias fuera de la oficina',
                showCantidadBase: false,
                extraUnidad: 'N° de horas o fracción',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
            {article:"33", text:'33° Cotejo de documentos',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas cotejadas',
                extraSelectYesAndNot: '¿Cotejo de partida parroquial?',
                extraSelect:[],
            },
            {article:"34", text:'34° Digitalización de su protocolo',
                showCantidadBase: false,
                extraUnidad: 'N° de folios o documentos del apéndice',
                extraSelectYesAndNot: '¿Incluye almacenamiento, resguardo o envío electrónico?',
                extraSelect:[],
            },
            {article:"35", text:'35° Testimonios, copias certificadas y certificaciones',
                showCantidadBase: false,
                extraUnidad: 'N° de páginas del documento',
                extraSelectYesAndNot: '',
                extraSelect:[],
            },
        ],
        tabla:[
            {
                limiteInferior: 192222.01,
                limiteSuperior: 384443.00,
                porcentaje: 1.125
            },
            {
                limiteInferior: 384443.01,
                limiteSuperior: 768892.00,
                porcentaje: .975
            },
            {
                limiteInferior: 768892.01,
                limiteSuperior: 1537781.00,
                porcentaje: .825
            },
            {
                limiteInferior: 1537781.01,
                limiteSuperior: 3075566.00,
                porcentaje: .675
            },
            {
                limiteInferior: 3075566.01,
                limiteSuperior: 6151133.00,
                porcentaje: .578
            },
            {
                limiteInferior: 6151133.01,
                limiteSuperior: 12302270.00,
                porcentaje: .420
            },
            {
                limiteInferior: 12302270.01,
                limiteSuperior: null,
                porcentaje: .327
            },
        ],
        tabla2:[
            {
                limiteSuperior: 582495,
                cuotaFija: 3639,
            },
            {
                limiteSuperior: 1164991,
                cuotaFija: 4843,
            },
            {
                limiteSuperior: 2329981,
                cuotaFija: 6064,
            },
            {
                limiteSuperior: null,
                cuotaFija: 7281,
            }
        ],
        tabla3:[
            {
                limiteSuperior: 1164999,
                cuotaFija: 2283,
            },
            {
                limiteSuperior: 2329997,
                cuotaFija: 4111,
            },
            {
                limiteSuperior: 4659993,
                cuotaFija: 7273,
            },
            {
                limiteSuperior: 9319989,
                cuotaFija: 12793,
            },
            {
                limiteSuperior: 18639981,
                cuotaFija: 21931,
            },
            {
                limiteSuperior: 37279960,
                cuotaFija: 36556,
            },
            {
                limiteSuperior: 75212388,
                cuotaFija: 46787,
            },
            {
                limiteSuperior: 149119838,
                cuotaFija: 70184,
            },
            {
                limiteSuperior: 298239677,
                cuotaFija: 93576,
            },
            {
                limiteSuperior: null,
                cuotaFija: 93577,
            },
        ],
        tabla4:[
            {
                limiteInferior: 192223.01,
                limiteSuperior: 384445.00,
                factor: 1.070
            },
            {
                limiteInferior: 384445.01,
                limiteSuperior: 768894.00,
                factor: .856
            },
            {
                limiteInferior: 768894.01,
                limiteSuperior: 1537789.00,
                factor: .642
            },
            {
                limiteInferior: 1537789.01,
                limiteSuperior: 3075576,
                factor: .428
            },
            {
                limiteInferior: 3075576.01,
                limiteSuperior: 6151154.00,
                factor: .214
            },
            {
                limiteInferior: 6151154.01,
                limiteSuperior: 11580437.00,
                factor: .107
            },
            {
                limiteInferior: 11580437.01,
                limiteSuperior: 43468645.00,
                factor: .053
            },
            {
                limiteInferior: 43468645.01,
                limiteSuperior: 86853288.00,
                factor: .026
            },
            {
                limiteInferior: 86853288.01,
                limiteSuperior: null,
                factor: .021
            },
        ]

    }),
    watch:{
        article(){
            this.criterios = this.articles.find(item => this.article === item.article);
            this.calcular();
        }
    },
    methods:{
        issetCalc(){
            this.issetCalcular = true;
            this.calcular();            
        },
        impresionArancel(arancel){
            const iva = arancel * .16;
            const total = arancel + iva;
            //<p>Subtotal $ ${this.criteriosValue.cantidadBase.toFixed(2)}</p>
            return `                
                <v-divider></v-divider>
                <p>Arancel ${arancel.toFixed(2)}</p>
                <v-divider></v-divider>
                <p>IVA: ${iva.toFixed(2)}</p>
                <p>Total: ${total.toFixed(2)}</p>
                `;
        },
        art15(){
            const cuota_fija = 5601;
            const tabla = this.tabla.find(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
            const tablaIndex = this.tabla.findIndex(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
            const base = this.criteriosValue.cantidadBase;

            if(base < tabla.limiteInferior || base === 0){
                return cuota_fija;
            }

            let calc_tabla_arancel = (base - tabla.limiteInferior) * (tabla.porcentaje / 100);
            for(let i=0; i<tablaIndex; i++) calc_tabla_arancel+= this.tabla[i].limiteInferior * (this.tabla[i].porcentaje / 100);

            return calc_tabla_arancel + cuota_fija;
        },
        art20(){
            const tabla = this.tabla3.find(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
            const unidadPrivativa = this.criteriosValue.extraUnidadValue * 1022;
            const arancel = tabla.cuotaFija + unidadPrivativa;
            return arancel;
        },
        calcular(){
            this.criteriosValue.cantidadBase = this.criteriosValue.cantidadBase < 1 ? 0 : this.criteriosValue.cantidadBase;
            this.textResult="";
            switch(this.article){
                case "15":
                case "16":
                case "17":{
                    if(this.issetCalcular){
                        this.textResult = this.impresionArancel(this.art15());
                    }
                    break;
                }
                case "18":{
                    const tabla = this.tabla2.find(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
                    this.textResult = this.impresionArancel(tabla.cuotaFija);
                    break;
                }
                case "19I":{
                    if(this.issetCalcular){
                        let arancel = this.art15();
                        if(this.criteriosValue.extraSelectYesAndNotValue === "Si"){
                            this.textResult = this.impresionArancel(arancel * .50);
                        }else{
                            this.textResult = this.impresionArancel(arancel);
                        }
                    }
                    break;
                }
                case "19II":{
                    if(this.issetCalcular){
                        const tabla = this.tabla2.find(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
                        if(this.criteriosValue.extraSelectYesAndNotValue === "Si"){
                            this.textResult = this.impresionArancel(tabla.cuotaFija * .50);
                        }else{
                            this.textResult = this.impresionArancel(tabla.cuotaFija);
                        }
                    }
                    break;
                }
                case "20":{
                    if(this.issetCalcular){
                        this.textResult = this.impresionArancel(this.art20());
                    }
                    break;
                }
                case "21":{
                    if(this.issetCalcular){
                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                            this.textResult = this.impresionArancel(10500);
                        }else{
                            this.textResult = this.impresionArancel(this.art20() * .50);
                        }
                    }
                    break;
                }
                case "22":{
                    if(this.issetCalcular){
                        const base = this.criteriosValue.cantidadBase;
                        if(base < this.tabla4[0].limiteInferior){
                            this.textResult = this.impresionArancel(7979);
                        }else{
                            const tabla = this.tabla4.find(item => (this.criteriosValue.cantidadBase <= item.limiteSuperior || item.limiteSuperior === null));
                            this.textResult = this.impresionArancel(7979 + ((base - tabla.limiteInferior) * (tabla.factor / 100)));
                        }
                    }
                    break;
                }
                case "22II":{
                    console.log("SI");
                    break;
                }
                case "22III":{
                    if(this.issetCalcular){ 
                        const paginas = this.criteriosValue.extraUnidadValue;
                        this.textResult = this.impresionArancel(12187 + (paginas * 121));
                    }
                    break;
                }
                case "23":{
                    if(this.issetCalcular){
                        const paginas = this.criteriosValue.extraUnidadValue-8;
                        let extras = paginas > 0 ? paginas : 0;
                        let arancel = 4020 + (extras * 399);                        

                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){                            
                            this.textResult = this.impresionArancel(arancel);
                        }else{
                            let arancel2 = 12187 + (paginas * 121);
                            if(arancel >= arancel2){
                                this.textResult = this.impresionArancel(arancel);
                            }else{
                                this.textResult = this.impresionArancel(arancel2);
                            }
                        }
                    }
                    break;
                }
                case "24I":{
                    if(this.issetCalcular){
                        let arancel;
                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                            if(this.criteriosValue.extraUnidadValue <= 1){
                                arancel = 2009;
                            }else{
                                arancel = 2009 + (200 * this.criteriosValue.extraUnidadValue);
                            }
                        }else{
                            if(this.criteriosValue.extraUnidadValue <= 1){
                                arancel = 11200;
                            }else{
                                arancel = 11200 + (200 * this.criteriosValue.extraUnidadValue);
                            }
                        }
                        this.textResult = this.impresionArancel(arancel);
                    }
                    break;
                }
                case "24II":{
                    if(this.issetCalcular){
                        if(this.criteriosValue.extraSelectYesAndNotSecondValue === "Si"){
                            this.textResult = this.impresionArancel(14000);
                        }else if(this.criteriosValue.extraSelectYesAndNotValue === "NO"){
                            this.textResult = this.impresionArancel(3639);
                        }else{
                            this.textResult = this.impresionArancel(601);
                        }
                    }
                    break;
                }
                case "24IV":{
                    if(this.issetCalcular){
                        if(this.criteriosValue.extraUnidadValue <= 1){
                            this.textResult = this.impresionArancel(2323);
                        }else{
                            this.textResult = this.impresionArancel(2323 + (this.criteriosValue.extraUnidadValue * 319));
                        }
                    }
                    break;
                }
                case "25":{
                    if(this.issetCalcular){                        
                        if(this.criteriosValue.extraSelectValue === "Testamento en la Notaría"){
                            this.textResult = this.impresionArancel(4339);
                        }else if(this.criteriosValue.extraSelectValue === "Testamento fuera de la notaría"){
                            this.textResult = this.impresionArancel(8680);
                        }else if(this.criteriosValue.extraSelectValue === "Documentos de voluntad anticipada"){
                            this.textResult = this.impresionArancel(1680);
                        }else if(this.criteriosValue.extraSelectValue === "Tutela cautelar"){
                            this.textResult = this.impresionArancel(1680);
                        }
                    }
                    break;
                }
                case "26I":{
                    if(this.issetCalcular){
                        if(this.criteriosValue.extraSelectValue === "Testamentaría"){
                            this.textResult = this.impresionArancel(9801);
                        }else{
                            this.textResult = this.impresionArancel(17667);
                        }
                    }
                    break;
                }
                case "26II":{
                    if(this.issetCalcular){
                        this.textResult = this.impresionArancel(this.criteriosValue.extraUnidadValue * .0075);
                    }
                    break;
                }
                case "26III":{
                    if(this.issetCalcular){
                        let arancel = this.article() + (this.criteriosValue.extraUnidadValue * .0050);
                        this.textResult = this.impresionArancel(arancel);
                    }
                    break;
                }
                case "27":{
                    if(this.issetCalcular){
                        if(this.criteriosValue.extraSelectValue === "Perosna fisica"){
                            this.textResult = this.impresionArancel(2008);
                        }else{
                            this.textResult = this.impresionArancel(3639);
                        }
                    }
                    break;
                }
                case "28I":{//revisar en arancel
                    if(this.issetCalcular){
                        let arancel;
                        let paginas = this.criteriosValue.extraUnidadValue - 3;
                        let paginasExtra = paginas > 0 ? paginas : 0;
                        if(this.criteriosValue.extraSelectYesAndNotValue === "No"){
                            arancel = 3639;
                        }else{
                            arancel = 2009;
                        }
                        this.textResult = this.impresionArancel(arancel + (paginasExtra * 45));
                    }
                    break;
                }
                case "28II":{
                    console.log("Hola");
                    if(this.issetCalcular){
                        let arancel = this.art15() * .50;                        
                        let paginas = this.criteriosValue.extraUnidadValue - 3;
                        let paginasExtra = paginas > 0 ? paginas : 0;
                        this.textResult = this.impresionArancel(arancel + (paginasExtra * 71));
                    }
                    break;
                }
                case "29":{
                    if(this.issetCalcular){
                        let arancel = 2170;
                        let paginas = this.criteriosValue.extraUnidadValue;
                        this.textResult = this.impresionArancel(arancel + (paginas * 435));
                    }
                    break;
                }
                case "30":{
                    if(this.issetCalcular){                        
                        let paginas = this.criteriosValue.extraUnidadValue;
                        this.textResult = this.impresionArancel(5601 * paginas);
                    }
                    break;
                }
                case "33":{
                    if(this.issetCalcular){                        
                        if(this.criteriosValue.extraUnidadValue === "No"){
                            let arancel = 420;
                            let paginas = this.criteriosValue.extraUnidadValue - 3;
                            let paginasExtra = paginas > 0 ? paginas : 0;
                            this.textResult = this.impresionArancel(arancel + (paginasExtra * 42));
                        }else{
                             this.textResult = this.impresionArancel(5601);
                        }

                    }
                    break;
                }
                case "34":{
                    if(this.issetCalcular){                        
                        let arancel = 70;
                        let paginas = this.criteriosValue.extraUnidadValue - 10;
                        let paginasExtra = paginas > 0 ? paginas : 0;
                        arancel+=5*paginasExtra;
                        if(this.criteriosValue.extraUnidadValue === "Si"){
                            arancel = arancel + (arancel * .50);
                        }
                             this.textResult = this.impresionArancel(arancel);                        
                    }
                    break;
                }
                case "35":{
                    if(this.issetCalcular){                        
                        let arancel = 560;
                        let paginas = this.criteriosValue.extraUnidadValue - 3;
                        let paginasExtra = paginas > 0 ? paginas : 0;
                        
                        this.textResult = this.impresionArancel(arancel + (paginasExtra*46));
                        break;
                    }
                }                
            }
        },
        toggleResult(){
            console.log("calcular2");
        }
    }
})
</script>
