<template>
    <ion-grid class="ion-padding">
        <ion-row class="ion-padding ion-justify-content-center ion-text-center oculto-impresion">
            <ion-col size="6" size-md="3">
                <ion-button
                    @click="regresar"
                ><ion-icon :src="returnUpBackOutline" size="large"></ion-icon>REGRESAR</ion-button>            
            </ion-col>
            <ion-col size="6" size-md="3">
                <ion-button
                @click="print"
                > <ion-icon :src="printOutline" size="large"></ion-icon> Imprimir</ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
            <ion-col size="12" class="ion-text-center">
                 <h2>Impuesto sobre adquisición de la Ciudad de México</h2>                 
            </ion-col>
            <ion-col size="12">
                <p>
                    Valor del inmueble: {{CantidadBase}}  <br/>
                    Porcentaje Adquirido: {{porcentaje}} <br/>
                    ¿Vivienda?: 
                    <strong v-if="op==='0'">No</strong>
                    <strong v-else-if="op==='1'">Si, de interes</strong>
                    <strong v-else-if="op==='2'">si, Popular/Progresiva</strong> <br/>
                    Impuesto Sobre Adquisición : $ {{resultado.total}}
                </p>
            </ion-col>
            <ion-col size="12">
                <h2>Detalle del cálculo</h2>
            </ion-col>
            <ion-col size="12">
                Renglon seleccionado de la tarifa
            </ion-col>
            <ion-col size="2">Rango</ion-col>
            <ion-col size="3">Límite inferior</ion-col>
            <ion-col size="3">Límite superior</ion-col>
            <ion-col size="2">Cuota fija</ion-col>
            <ion-col size="2">Factor</ion-col>

            <ion-col size="2"><strong>{{filaCompleta.fila}}</strong></ion-col>
            <ion-col size="3"><strong>{{filaCompleta.limite_inferior}}</strong></ion-col>
            <ion-col size="3"><strong>{{filaCompleta.limite_superior > 0 ? filaCompleta.limite_superior : "EN ADELANTE"}}</strong></ion-col>
            <ion-col size="2"><strong>{{filaCompleta.cuota_fija}}</strong></ion-col>
            <ion-col size="2"><strong>{{filaCompleta.factor}}</strong></ion-col>

            <ion-col size="12">
                <p>
                    Valor a aplicar la tarifa: <strong>{{CantidadBase}}</strong> <br/>
                    Determina el excedente: Valor a aplicar la tarifa - limite inferior<br/>
                    Excedente del limite inferior: <strong>{{resultado.paso1}}</strong><br/>
                    Importe por el excedente = Excedente del Limite Inferior * Factor del Excedente<br/>
                    Importe por el excedente : <strong>{{resultado.paso2}}</strong> <br/>
                    Total de impuesto = importe por el excedente + cuota fija <br/>
                    total de impuesto : <strong>{{resultado.paso3}}</strong> <br/>
                    Determina porcentaje: Resultado * porcentaje obtenido <br/>
                    ¿Vivienda?:
                    <strong v-if="op==='0'">No</strong>
                    <strong v-else-if="op==='1'">Si, de interes</strong>
                    <strong v-else-if="op==='2'">si, Popular/Progresiva</strong><br/>
                    Total de impuesto : <strong>{{resultado.total}}</strong> <br/>
                </p>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>

import {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon
} from '@ionic/vue';

import { defineComponent } from 'vue';
import {returnUpBackOutline, printOutline} from 'ionicons/icons';

export default defineComponent({
    name:'Result-Isai',
    props:['CantidadBase', 'porcentaje', 'op', 'filaCompleta', 'resultado', 'toggleResult'],
    components:{
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
        IonIcon
    },
    setup(){
        return {
            returnUpBackOutline,
            printOutline
        }
    },
    methods:{
        regresar(){
            this.$emit('toggleResult');
        },
        print(){
            window.print();
        }
    }
})
</script>
