<template>
        <input 
            ref="inputRef" 
            placeholder="$$$" 
            class="input-currency"
        />
</template>

<script>

import { useCurrencyInput } from 'vue-currency-input';
import { defineComponent, watch } from 'vue';

export default defineComponent({
    name:'CurrencyInput',
 //   https://dm4t2.github.io/vue-currency-input/guide.html#creating-a-custom-component
    components:{

    },

    props: {
        modelValue: Number,
        options: Object
    },
    setup(props) {
        const { inputRef, setOptions, setValue } = useCurrencyInput({
            locale: 'en-US',
            currency: 'USD',
            currencyDisplay: 'hidden',
            valueRange: {
                min: 0,
                max: 100000000000
            },
            precision: undefined,
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: true,
            autoDecimalDigits: false,
            exportValueAsInteger: false,
            autoSign: true,
            useGrouping: true
        })

        watch(
        () => props.modelValue, // Vue 2: props.value
        (value) => {
            setValue(value)
        }
        )

        watch(
            () => props.options,
            (options) => {
                setOptions(options)
            }
        )

        return { inputRef }
  }
    
})
</script>

<style>

.input-currency{
    width: 100%;
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;    
    text-align: center;
}

</style>