<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>ISABI de la Ciudad de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid v-if="!showResult">
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="5" size-lg="3">
                        <h1>Cantidad base</h1>
                        <ion-item>
                            <currency-input v-model="CantidadBase"/>
                        </ion-item>
                        <ion-item class="ion-margin-top">
                            <ion-label position="stacked">Stacked Label</ion-label>
                            <ion-input type="number" v-model="porcentaje"></ion-input>
                        </ion-item>
                        <ion-list class="ion-margin-top">
                            <ion-radio-group value="0" v-model="op">
                                <ion-list-header>
                                    <ion-label>¿Vivienda?</ion-label>
                                </ion-list-header>

                                <ion-item>
                                    <ion-label>No, ninguna de las sigueintes</ion-label>
                                    <ion-radio slot="start" value="0"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>Interés social</ion-label>
                                    <ion-radio slot="start" value="1"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>Interés popular</ion-label>
                                    <ion-radio slot="start" value="2"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>
                        <ion-button 
                            class="ion-margin-top"
                            @click="calcular"
                        >Cálcular</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>            
            <Result
                v-else                
                :CantidadBase = CantidadBase
                :porcentaje = porcentaje
                :op = op
                :filaCompleta = filaCompleta
                :resultado = resultado
                @toggleResult = toggleResult
            />
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonInput, 
    IonItem,
    IonButton,
    IonLabel,
    IonList, 
    IonListHeader,
    IonRadio, 
    IonRadioGroup
} from '@ionic/vue';
import { defineComponent } from 'vue';
import Result from '../../../components/cdmx/IsabiResultado/Result.vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'isabi-cdmx-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonInput, 
        IonItem,
        IonButton,
        IonLabel,
        IonList, 
        IonListHeader,
        IonRadio, 
        IonRadioGroup,
        Result,
        CurrencyInput
    },
    data:()=>({
        CantidadBase: 0.00,
        tabla : [
            {
                fila: 'A',
                limite_inferior: .12,
                limite_superior: 111635.49,
                cuota_fija: 270.65,
                factor: .01384
            },
            {
                fila: 'B',
                limite_inferior: 111635.50,
                limite_superior: 178616.73,
                cuota_fija: 1623.69,
                factor: 0.02946
            },
            {
                fila: 'C',
                limite_inferior: 178616.74,
                limite_superior: 267924.84,
                cuota_fija: 3352.48,
                factor: 0.03850
            },
            {
                fila: 'D',
                limite_inferior: 267924.85,
                limite_superior: 535849.82,
                cuota_fija: 6363.97,
                factor: 0.04491
            },
            {
                fila: 'E',
                limite_inferior: 535849.83,
                limite_superior: 1339624.52,
                cuota_fija: 16904.14,
                factor: 0.04990
            },
            {
                fila: 'F',
                limite_inferior: 1339624.53,
                limite_superior: 2679249.06,
                cuota_fija: 52029.11,
                factor: 0.05451
            },
            {
                fila: 'G',
                limite_inferior: 2679249.07,
                limite_superior: 5161335.15,
                cuota_fija: 115982.80,
                factor: 0.05913
            },
            {
                fila: 'H',
                limite_inferior: 5161335.16,
                limite_superior: 13440977.59,
                cuota_fija: 244530.05,
                factor: 0.06149
            },
            {
                fila: 'I',
                limite_inferior: 13440977.60,
                limite_superior: 24787061.85,
                cuota_fija: 690471.61,
                factor: 0.06202
            },
            {
                fila: 'J',
                limite_inferior: 24787061.86,
                limite_superior: 49574123.67,
                cuota_fija: 1306904.36,
                factor: 0.06258
            },
            {
                fila: 'K',
                limite_inferior: 49574123.68,
                limite_superior: null,
                cuota_fija: 2665483.23,
                factor: 0.06798
            }
        ],
        porcentaje: 100,
        salario: 96.22,
        op:"0",
        filaCompleta: {},
        resultado: {
            paso1:0,
            paso2:0,
            paso3:0,
            total:0,
        },
        showResult: false,
    }),
    methods:{
        calcular(){
            let CantidadBase = (this.CantidadBase === null || this.CantidadBase === 0) ? 1 : this.CantidadBase;
            this.filaCompleta = this.tabla.find(item => (CantidadBase <= item.limite_superior || item.limite_superior === null));
            let resultado = this.resultado;
            resultado.paso1 = CantidadBase - this.filaCompleta.limite_inferior;
            resultado.paso2 = resultado.paso1 * this.filaCompleta.factor;
            resultado.paso3 = resultado.paso2 + this.filaCompleta.cuota_fija;
            resultado.total = resultado.paso3 * (this.porcentaje/100);
            resultado.total = resultado.total.toFixed(2);
            if(this.op === "1"){
                let hasta = this.salario*5400;
                if(this.CantidadBase<=hasta){
                    resultado.total = resultado.total*.20; 
                }
            }else if(this.op === "2"){
                const de = this.salario*5400;
                let hasta = this.salario*10800;
                if(this.CantidadBase>=de && this.CantidadBase<=hasta){
                    resultado.total = resultado.total*.40;                        
                }
            }
            this.toggleResult();
        },
        toggleResult(){
            this.showResult = !this.showResult;
        }
    }
})
</script>
