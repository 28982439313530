<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="ion-text-center">
                <ion-title>ISABI del Estado de México 2022</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid v-if="!showResult">
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="12" class="ion-text-align-center">
                        <ion-button router-link="/home">
                            Regresar
                        </ion-button>
                    </ion-col>
                    <ion-col size="12" size-md="5" size-lg="3">
                        <h1>Cantidad base</h1>
                        <ion-item>
                            <currency-input v-model="CantidadBase"/>
                        </ion-item>
                        <ion-item class="ion-margin-top">
                            <ion-label position="stacked">Porcentaje</ion-label>
                            <ion-input type="number" v-model="porcentaje"></ion-input>
                        </ion-item>
                        <ion-button 
                            class="ion-margin-top"
                            @click="calcular"
                        >Cálcular</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>            
            <Result
                v-else                
                :CantidadBase = CantidadBase
                :porcentaje = porcentaje
                :filaCompleta = filaCompleta
                :resultado = resultado
                @toggleResult = toggleResult
            />
        </ion-content>
    </ion-page>    
</template>

<script>

import {
    IonContent, 
    IonHeader, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    IonCol, 
    IonGrid, 
    IonRow,
    IonInput, 
    IonItem,
    IonButton,
    IonLabel,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import Result from '../../../components/edomex/IsabiResultado/ResultEdomex.vue';
import CurrencyInput from '../../../components/CurrencyInput.vue';

export default defineComponent({
    name: 'isabi-edomex-2022',
    components:{
        IonContent, 
        IonHeader, 
        IonPage, 
        IonTitle, 
        IonToolbar,
        IonCol, 
        IonGrid, 
        IonRow,
        IonInput, 
        IonItem,
        IonButton,
        IonLabel,
        Result,
        CurrencyInput
    },
    data:()=>({
        CantidadBase: 0.00,
        tabla : [
            {
                fila: '1',
                limite_inferior: 1,
                limite_superior: 180970,
                cuota_fija: 214,
                factor: 0.01300
            },
            {
                fila: '2',
                limite_inferior: 180971,
                limite_superior: 310474,
                cuota_fija: 2353,
                factor: 0.01418
            },
            {
                fila: '3',
                limite_inferior: 310475,
                limite_superior: 532669,
                cuota_fija: 4404,
                factor: 0.01542
            },
            {
                fila: '4',
                limite_inferior: 532670,
                limite_superior: 913882,
                cuota_fija: 8214,
                factor: 0.01677
            },
            {
                fila: '5',
                limite_inferior: 913883,
                limite_superior: 1587915,
                cuota_fija: 15321,
                factor: 0.01823
            },
            {
                fila: '6',
                limite_inferior: 1567916,
                limite_superior: 2690015,
                cuota_fija: 28578,
                factor: 0.01982
            },
            {
                fila: '7',
                limite_inferior: 2690016,
                limite_superior: 4615162,
                cuota_fija: 53306,
                factor: 0.02154
            },
            {
                fila: '8',
                limite_inferior: 4615163,
                limite_superior: 7918066,
                cuota_fija: 99430,
                factor: 0.02342
            },
            {
                fila: '9',
                limite_inferior: 7918067,
                limite_superior: 13584739,
                cuota_fija: 185463,
                factor: 0.02547
            },
            {
                fila: '10',
                limite_inferior: 24787061.86,
                limite_superior: 49574123.67,
                cuota_fija: 1306904.36,
                factor: 0.06258
            },
            {
                fila: '11',
                limite_inferior: 13584740,
                limite_superior: 23306845,
                cuota_fija: 345936,
                factor: 0.02769
            },
            {
                fila: '12',
                limite_inferior: 23306846,
                limite_superior: 39999999,
                cuota_fija: 645260,
                factor: 0.03000
            },
            {
                fila: '13',
                limite_inferior: 40000000,
                limite_superior: "EN ADELANTE",
                cuota_fija: 1200000,
                factor: 0.03252
            }
        ],
        porcentaje: 100,
        filaCompleta: {},
        resultado: {
            paso1:0,
            paso2:0,
            paso3:0,
            total:0,
        },
        showResult: false,
    }),
    methods:{
        calcular(){
            let CantidadBase = (this.CantidadBase === null || this.CantidadBase === 0) ? 1 : this.CantidadBase;
            this.filaCompleta = this.tabla.find(item => CantidadBase <= item.limite_superior);
            let resultado = this.resultado;
            resultado.paso1 = CantidadBase - this.filaCompleta.limite_inferior;
            resultado.paso2 = resultado.paso1 * this.filaCompleta.factor;
            resultado.paso3 = resultado.paso2 + this.filaCompleta.cuota_fija;
            resultado.total = resultado.paso3 * (this.porcentaje/100);
            resultado.total = resultado.total.toFixed(2);
            
            this.toggleResult();
        },
        toggleResult(){
            this.showResult = !this.showResult;
        }
    }
})
</script>
