<template>
  <ion-card>
    <ion-card-content>
        <ion-img :src="srcImage" class="img-card"></ion-img>
        <ion-label>{{title}}</ion-label>
    </ion-card-content>

    <ion-item class="item-sombrear ion-activatable" :router-link="routes.arancel">
      <ion-icon :icon="newspaperOutline" slot="start"></ion-icon>
      <ion-label>Arancel</ion-label>
    </ion-item>

    <ion-item class="item-sombrear ion-activatable" :router-link="routes.isabi">
      <ion-icon :icon="bookOutline" slot="start"></ion-icon>
      <ion-label>Isabi</ion-label>
    </ion-item>

    <ion-item class="item-sombrear ion-activatable" :router-link="routes.derechos">
      <ion-icon :icon="documentTextOutline" slot="start"></ion-icon>
      <ion-label>Derechos</ion-label>
    </ion-item>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonImg } from '@ionic/vue';
import { newspaperOutline, bookOutline, documentTextOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
    name:'CardMenu',
    props:['srcImage', 'title', 'routes'],
    components: { IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonImg },
    setup() {
        return { newspaperOutline, bookOutline, documentTextOutline };
    }
});
</script>

<style scoped>
.img-card{
    width: 50px;
    margin: 0 auto;
}
.item-sombrear{
    cursor: pointer;
}
</style>