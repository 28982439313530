
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import CardMenu from '../components/CardMenu.vue';
export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonGrid,
    IonRow,
    CardMenu
  },
  setup (){
    const items = [
      {
        route:'/assets/images/cdmx.png',
        title:'CIudad de México',
        routes:{
          arancel:'/cdmx/2022/Arancel',
          isabi:'/cdmx/2022/Isabi',
          derechos:'/cdmx/2022/Derechos'
        }
      },
      {
        route:'/assets/images/edomex.png',
        title:'EStado de México',
        routes:{
          arancel:'/edomex/2022/Arancel',
          isabi:'/edomex/2022/Isabi',
          derechos:'/edomex/2022/Derechos'
        }
      }
    ]

    return {items};
  },
  data:()=>({
    
  }),
  methods:{
    pruebas(){
      alert("Hola");
    },
  }
});
