import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import cdmxIsabi2022 from '../views/cdmx/2022/Isabi.vue';
import cdmxArancel2022 from '../views/cdmx/2022/Arancel.vue';
import cdmxDerechos2022 from '../views/cdmx/2022/Derechos.vue';
import edomexIsabi2022 from '../views/edomex/2022/Isabi.vue';
import edomexArancel2022 from '../views/edomex/2022/Arancel.vue';
import edomexDerechos2022 from '../views/edomex/2022/Derechos.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/cdmx/2022/Isabi',
    name: 'isabi-cdmx-2022',
    component: cdmxIsabi2022
  },
  {
    path:'/cdmx/2022/Derechos',
    name:'derechos-cdmx-2022',
    component: cdmxDerechos2022
  },
  {
    path: '/cdmx/2022/Arancel',
    name: 'arancel-cdmx-2022',
    component: cdmxArancel2022
  },
  {
    path: '/edomex/2022/Isabi',
    name: 'isabi-edomex-2022',
    component: edomexIsabi2022
  },
  {
    path:'/edomex/2022/Derechos',
    name:'derechos-edomex-2022',
    component: edomexDerechos2022
  },
  {
    path: '/edomex/2022/Arancel',
    name: 'arancel-edomex-2022',
    component: edomexArancel2022
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
